import _ from 'lodash';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { getThemeAppLocal } from 'generic/utils/utils';

import THEME_CONFIG from 'generic/core/theme';

const useColorSchemeDetector = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const themeApp = useSelector((state) => state.config.user.theme_qes); // 1 || 2 || 1000

  let currentTheme = _.cloneDeep(THEME_CONFIG.LIGHT);

  const setTheme = (themeId) => {
    if ((themeId === 1000 && prefersDarkMode) || themeId === 2) {
      currentTheme = _.merge({}, currentTheme, THEME_CONFIG.DARK);
    }
  };

  if (window.location.pathname.startsWith('/log')) {
    setTheme(getThemeAppLocal());
  } else {
    setTheme(themeApp);
  }

  return currentTheme;
};

// eslint-disable-next-line import/prefer-default-export
export { useColorSchemeDetector };
