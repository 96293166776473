/**
 * QES est rempli de constantes obsolètes, de doublons, de code mort, ...
 * Il serait idiot de copier TOUTES les constantes issue de l'historique QES
 * On ajoutera au fil de l'eau les constantes nécessaires.
 * Pour simplifier l'accès aux informations, merci de préciser en commentaire
 * au dessus de la constante les requêtes SQL qui ont permis de retrouver l'info
 */

import _ from 'lodash';
import { CUSTOM_PATH } from 'generic/core/constants';
import i18next from 'i18next';

const imgExtensions = '.jpg, .jpeg, .gif, .png, .tiff';
const officeExtensions = '.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .txt, .csv';

const dateIntervalComparator = {
  less_than: 1,
  equal: 2,
  more_than: 3,
  between: 4,
  unknown: 1000,
};

const openUserStatus = 1;

let constants = {
  API_ENTRY_POINT: process.env.REACT_APP_API_ENTRY_POINT,

  // en dur dans le code de qes
  FACET_POSITION_MAPPING: {
    left: 1,
    right: 2,
    undefined: 1000,
  },

  // requête par défaut pour les recherches globales sur la base (dashboard, entrée sur la page de résultat en direct, ...)
  DEFAULT_SEARCH_PER_BASE: {
    default: {
      DATE_141_comp: dateIntervalComparator.less_than,
      DATE_141_from: '01/01/2100',
    },
  },

  // SELECT * FROM Format_saisie;
  INPUT_FORMAT: {
    date_with_comparator: 7,
    number: 13,
    text: 19,
    dropdown_list: 20,
    autocomplete_column_direct_regex: 38,
  },

  // SELECT * FROM Format_document;
  DOCUMENT_FORMAT: {
    COMPLET: 1,
    VEILLE: 3,
    RESUME: 4,
    TITRE: 5,
    FIL: 9,
    NOTIFICATION_AU_SERVEUR: 10,
    NON_PRECISE: 1000,
  },

  // SELECT * FROM Etat_document;
  ETAT_DOCUMENT: {
    NOUVEAU: 1,
    DOUBLON: 2,
    REJETE: 3,
    COLLECTE: 4,
    ERREUR: 5,
    VALIDE: 50,
    PUBLIE: 100,
    SUPPRIMER: 203,
    LIER: 204,
    FUSIONNER: 205,
    COMMENTER: 206,
    DUPLIQUER: 207,
    RANKING: 220,
    DEDOUBLONNER: 230,
    FAVORI: 240,
    NON_PRECISE: 1000,
    A_COMPLETER: 1001,
  },

  FILE_UPLOAD_FORMATS: {
    csv: {
      backOfficeType: '1',
      fileExtensions: '.csv, .txt',
    },
    refman: {
      backOfficeType: '2',
      fileExtensions: '.xml, .ris, .txt',
    },
    cart: {
      backOfficeType: '3',
      fileExtensions: imgExtensions,
    },
    newsletter: {
      backOfficeType: '3',
      fileExtensions: imgExtensions,
    },
    trombi: {
      backOfficeType: '4',
      fileExtensions: imgExtensions,
    },
    plateform: {
      backOfficeType: '3',
      fileExtensions: imgExtensions,
    },
    docToUpload: {
      fileExtensions: `${imgExtensions}, ${officeExtensions}`,
    },
  },

  // SELECT * FROM Civilite;
  CIVILITY: {
    mrs: 1,
    miss: 2,
    mr: 3,
    unknown: 1000,
  },

  // SELECT * FROM Etat_veille
  // SELECT * FROM Type_veille
  // On ne garde que les typologies intéressantes pour le front
  ALERT: {
    STATUSES: {
      DEFAULT_ACTIVE: 2,
      DEFAULT_INACTIVE: 5,
      ACTIVE: [1, 2],
      INACTIVE: [5],
    },
    TYPES: {
      ALERT: 1,
      REQUEST: 6,
    },
  },

  OPENED_USER_REQUEST: '1',
  CLOSED_USER_REQUEST: '2,3,4',
  OPEN_USER_STATUS: openUserStatus,
  CLOSED_USER_STATUS: 3,

  TYPE_PJ_WITHOUT: '1',

  // SELECT * FROM Raffinage;
  REFINING_STRATEGY: {
    adjacency: 1,
    semantics: 2,
    exact_expression: 10,
    as_is: 1000,
  },

  // SELECT * FROM Comparateur;
  DATE_INTERVAL_COMPARATOR: dateIntervalComparator,

  // SELECT * FROM Visibilite_panier;
  CART_VISIBILITY: {
    private: 1000,
    all: 3,
    collaborative: 1,
    company: 2,
    profile: 4,
  },
  NEWSLETTER_VISIBILITY: {
    private: 1000,
    all: 3,
    collaborative: 1,
    company: 2,
    profile: 4,
  },

  DEFAULT_USER: {
    civilite: 1,
    email: '',
    logon: '',
    utilisateur: null,
    nom: '',
    prenom: '',
    etat_utilisateur: openUserStatus,
  },

  CRITERES: {
    AND: 1,
    OR: 2,
    NOT: 3,
  },

  /* ---------- PARAMETRES ERGONOMIQUES A OVERRIDER ---------- */
  // Option pour modifier le comportement du clic sur un titre d'article dans le search :
  // 'newTab' > ouvre l'info dans un nouvel onglet (pas de bouton pour ouvrir dans le complet)
  // 'complete' > ouvre le complet
  CLICK_ON_DOCS_TITLE_BEHAVIOR: 'complete',
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: true,
  // Taille en "unité" de Grid MUI pour les colonnes des facettes
  MAX_FACET_COLUMN_WIDTH: 2,
  // Nombre de facettes affichées quand on est en mode "shrinked"
  MAX_FACET_DISPLAYED_WHEN_SHRINKED: 10,

  DASHBOARDS_PARAMS_PER_BASE: {
    default: {
      WIDGETS: [
        {
          i: 'publicationDates',
          x: 0,
          y: 0,
          w: 6,
          h: 1,
          facets: 'PUB_DATE#auto_date_histogram|300 _key:asc',
          aggregates: ['agg_PUB_DATE'],
          type: 'spline',
          title: i18next.t('dashboard.widget.documents_published_by_dates'),
        },
        {
          i: 'sources',
          x: 0,
          y: 1,
          w: 2,
          h: 1,
          facets: 'SOURCE.verbatim',
          aggregates: ['agg_SOURCE.verbatim'],
          type: 'pie',
          monoColors: true,
          title: i18next.t('dashboard.widget.sources_mentioned'),
        },
        {
          i: 'documentStatus',
          x: 2,
          y: 1,
          w: 2,
          h: 1,
          facets: 'ETAT',
          aggregates: ['agg_ETAT'],
          humanizeDocState: true,
          type: 'treemap',
          title: i18next.t('dashboard.widget.status_documents'),
        },
        {
          i: 'documentGaugeStatus',
          x: 4,
          y: 1,
          w: 2,
          h: 1,
          facets: 'ETAT',
          aggregates: ['agg_ETAT'],
          humanizeDocState: true,
          type: 'solidgauge',
          title: i18next.t('dashboard.widget.status_documents'),
        },
        {
          i: 'sentiments',
          x: 0,
          y: 2,
          w: 2,
          h: 1,
          facets: 'QES_SentimentForce',
          aggregates: ['agg_QES_SentimentForce'],
          type: 'pie',
          tones: true,
          title: i18next.t('dashboard.widget.tonalities'),
        },
        {
          i: 'countryTopic',
          x: 2,
          y: 2,
          w: 2,
          h: 1,
          facets: 'QES_CountryTopicCode.verbatim',
          aggregates: ['agg_QES_CountryTopicCode.verbatim'],
          type: 'map',
          mindoccount: 1,
          facetmax: 10000,
          facetmax2: 10000,
          title: i18next.t('dashboard.widget.countries_mentioned'),
        },
        {
          i: 'lastPublishedDocuments',
          x: 4,
          y: 2,
          w: 2,
          h: 1,
          additionalQuery: 'ETAT:100',
          slice: 25,
          sort: 'DATE_COLLECT Desc',
          type: 'documentlist',
          title: i18next.t('dashboard.widget.latest_published_documents'),
        },
        {
          i: 'locations',
          x: 0,
          y: 3,
          w: 3,
          h: 1,
          facets: 'QES_Location.verbatim',
          aggregates: ['agg_QES_Location.verbatim'],
          type: 'bar',
          title: i18next.t('dashboard.widget.places_mentioned_in_documents'),
        },
        {
          i: 'conceptsPersons',
          x: 3,
          y: 3,
          w: 3,
          h: 1,
          facets: 'QES_Person.verbatim, QES_Concept.verbatim',
          aggregates: ['agg_QES_Person.verbatim'],
          pivots: ['agg_QES_Concept.verbatim'],
          facetmax: 5,
          facetmax2: 10,
          mindoccount: 1,
          type: 'sankey',
          title: i18next.t('dashboard.widget.persons_and_concepts'),
        },
        {
          i: 'sourcesByOriginFolders',
          x: 0,
          y: 4,
          w: 2,
          h: 1,
          facets: 'FOLDER_ID.verbatim, SOURCE.verbatim',
          aggregates: ['agg_FOLDER_ID.verbatim'],
          pivots: ['agg_SOURCE.verbatim'],
          list: 1001701270,
          type: 'sunburst',
          title: i18next.t('dashboard.widget.sources_by_original_folders'),
        },
        {
          i: 'persons',
          x: 2,
          y: 4,
          w: 2,
          h: 1,
          facets: 'QES_Person.verbatim',
          aggregates: ['agg_QES_Person.verbatim'],
          type: 'pie',
          title: i18next.t('dashboard.widget.persons_mentioned'),
        },
        {
          i: 'companies',
          x: 4,
          y: 4,
          w: 2,
          h: 1,
          facets: 'QES_Company.verbatim',
          aggregates: ['agg_QES_Company.verbatim'],
          type: 'pie',
          title: i18next.t('dashboard.widget.compagnies_mentioned'),
        },
        {
          i: 'concepts',
          x: 0,
          y: 5,
          w: 2,
          h: 1,
          facets: 'QES_ConceptCategorized.verbatim',
          aggregates: ['agg_QES_ConceptCategorized.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.concepts'),
        },
        {
          i: 'environmental_concepts',
          x: 2,
          y: 5,
          w: 2,
          h: 1,
          facets: 'QES_ConceptEnvironment.verbatim',
          aggregates: ['agg_QES_ConceptEnvironment.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.environmental_concepts'),
        },
        {
          i: 'energy_concepts',
          x: 4,
          y: 5,
          w: 2,
          h: 1,
          facets: 'QES_ConceptEnergy.verbatim',
          aggregates: ['agg_QES_ConceptEnergy.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.energy_concepts'),
        },
        {
          i: 'business_concepts',
          x: 0,
          y: 6,
          w: 2,
          h: 1,
          facets: 'QES_ConceptBusiness.verbatim',
          aggregates: ['agg_QES_ConceptBusiness.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.business_concepts'),
        },
        {
          i: 'political_concepts',
          x: 2,
          y: 6,
          w: 2,
          h: 1,
          facets: 'QES_ConceptPolitics.verbatim',
          aggregates: ['agg_QES_ConceptPolitics.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.political_concepts'),
        },
        {
          i: 'justice_concepts',
          x: 4,
          y: 6,
          w: 2,
          h: 1,
          facets: 'QES_ConceptJustice.verbatim',
          aggregates: ['agg_QES_ConceptJustice.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.justice_concepts'),
        },
        {
          i: 'innovation_concepts',
          x: 0,
          y: 7,
          w: 2,
          h: 1,
          facets: 'QES_ConceptInnovation.verbatim',
          aggregates: ['agg_QES_ConceptInnovation.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.innovation_concepts'),
        },
        {
          i: 'technology_concepts',
          x: 2,
          y: 7,
          w: 2,
          h: 1,
          facets: 'QES_ConceptTechnology.verbatim',
          aggregates: ['agg_QES_ConceptTechnology.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.technology_concepts'),
        },
        {
          i: 'culture_concepts',
          x: 4,
          y: 7,
          w: 2,
          h: 1,
          facets: 'QES_ConceptCulture.verbatim',
          aggregates: ['agg_QES_ConceptCulture.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.culture_concepts'),
        },
        {
          i: 'education_concepts',
          x: 0,
          y: 8,
          w: 2,
          h: 1,
          facets: 'QES_ConceptEducation.verbatim',
          aggregates: ['agg_QES_ConceptEducation.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.education_concepts'),
        },
        {
          i: 'security_concepts',
          x: 2,
          y: 8,
          w: 2,
          h: 1,
          facets: 'QES_ConceptSecurity.verbatim',
          aggregates: ['agg_QES_ConceptSecurity.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.security_concepts'),
        },
        {
          i: 'society_concepts',
          x: 4,
          y: 8,
          w: 2,
          h: 1,
          facets: 'QES_ConceptSociety.verbatim',
          aggregates: ['agg_QES_ConceptSociety.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.society_concepts'),
        },
        {
          i: 'health_concepts',
          x: 0,
          y: 9,
          w: 2,
          h: 1,
          facets: 'QES_ConceptHealth.verbatim',
          aggregates: ['agg_QES_ConceptHealth.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.health_concepts'),
        },
        {
          i: 'sport_concepts',
          x: 2,
          y: 9,
          w: 2,
          h: 1,
          facets: 'QES_ConceptSport.verbatim',
          aggregates: ['agg_QES_ConceptSport.verbatim'],
          facetmax: 50,
          type: 'wordcloud',
          title: i18next.t('dashboard.widget.sport_concepts'),
        },
        {
          i: 'concepts_datatable',
          x: 4,
          y: 9,
          w: 2,
          h: 1,
          facets: 'QES_ConceptCategorized.verbatim',
          aggregates: ['agg_QES_ConceptCategorized.verbatim'],
          facetmax: 50,
          type: 'datatable',
          title: i18next.t('dashboard.widget.concepts'),
        },
        {
          i: 'cooccurrences',
          x: 0,
          y: 10,
          w: 6,
          h: 2,
          facetmax: 200,
          facetmax2: 5,
          mindoccount: 1,
          type: 'networkgraph',
          title: i18next.t('dashboard.widget.cooccurrences_graph'),
        },
      ],

      COOCCURRENCES_FIELDS: [
        { name: 'Personnes', value: 'QES_Person.verbatim', active: true },
        { name: 'Sociétés', value: 'QES_Company.verbatim', active: true },
        { name: 'Concepts', value: 'QES_ConceptCategorized.verbatim', active: true },
      ],
    },
  },
  DYNAMIC_DASHBOARD: true,
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customConstants } = require(`custom/${CUSTOM_PATH}/core/qes/constants`);
    constants = _.merge(constants, customConstants);
    if (!_.isEmpty(customConstants.override_all)) {
      constants = {
        ...constants,
        ...customConstants.override_all,
      };
    }
  } catch (err) {
    console.info('Pas de fichier custom de constantes QES');
  }
}

const QES_CONSTANTS = constants;
export default QES_CONSTANTS;
