import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  List,
  ListItem,
  Box,
  Drawer,
  Typography,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { overrideRessource } from 'generic/utils/utils';
import ResultSingleActions from 'generic/components/ui/ResultSingleAction';
import CommentsContainer from 'generic/containers/CommentsContainer';
import MoreLikeThis from 'generic/components/ui/MoreLikeThis';

const Document = overrideRessource('components/documents/Document');

const ResultsComplete = ({
  canAddToCart,
  canAddToNewsletter,
  canEditInGED,
  canFastEditInGED,
  canComment,
  canDeleteDocument,
  canSendToFriend,
  withMoreLikeThis,
  handleClose,
  showButtons,
  resultsComplete,
  openFastGed,
  titleFieldName,
  variant,
  disableTagsRefine,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open
      variant={variant}
      onClose={handleClose}
      sx={variant === 'permanent' ? { flex: '1 1 auto', display: 'flex' } : {}}
      PaperProps={{
        sx: {
          width: { xs: '85%', md: '70%', lg: '60%' },
          position: variant === 'permanent' ? 'relative' : 'fixed',
        },
      }}
    >
      {!_.isEmpty(resultsComplete.documents) && (
        <List
          dense
          sx={{ ml: 0, pt: 0 }}
        >
          {
            resultsComplete.documents.map(
              (document, index) => {
                const title = _.get(
                  document.qesdocument,
                  `${titleFieldName}[0]`,
                  [`[${t('document.title_undefined')}]`],
                );
                return (
                  <ListItem
                    display="block"
                    key={document.documentitem}
                    sx={{
                      mb: 2,
                      px: 2,
                      pt: 0,
                      display: 'block',
                    }}
                  >
                    {index > 0 && (
                      <Divider sx={{ mx: 2 }} />
                    )}
                    <Box
                      className="stickyHeader"
                      py={1}
                      zIndex="1"
                    >
                      <Typography variant="h5">
                        {title}
                      </Typography>
                      {showButtons && (
                        <Box py={0.5}>
                          <ResultSingleActions
                            canAddToCart={canAddToCart}
                            canAddToNewsletter={canAddToNewsletter}
                            canEditInGED={canEditInGED}
                            canFastEditInGed={canFastEditInGED}
                            canDeleteDocument={canDeleteDocument}
                            canSendToFriend={canSendToFriend}
                            document={document}
                            handleOpenFastGed={() => { openFastGed(document.idext, document.base); }}
                            visible
                          />
                        </Box>
                      )}
                    </Box>
                    <Document
                      isCompleteDisplay
                      disableTagsRefine={disableTagsRefine}
                      document={document}
                      displayTitle={false}
                    />
                    {withMoreLikeThis && (
                      <MoreLikeThis
                        qesdocuments={document.morelikethis}
                        loading={document.loadingMoreLikeThis}
                        moreLikeThisIsVisible
                        showSeeAlsoLabel
                      />
                    )}
                    {canComment && (
                      <CommentsContainer
                        document={document}
                        commentIsVisible
                        autoFocusInput={false}
                      />
                    )}
                  </ListItem>
                );
              },
            )
          }
        </List>
      )}
    </Drawer>
  );
};

ResultsComplete.propTypes = {
  canAddToCart: PropTypes.bool,
  canAddToNewsletter: PropTypes.bool,
  canEditInGED: PropTypes.bool,
  canFastEditInGED: PropTypes.bool,
  canDeleteDocument: PropTypes.bool,
  canSendToFriend: PropTypes.bool,
  canComment: PropTypes.bool,
  disableTagsRefine: PropTypes.bool,
  withMoreLikeThis: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  showButtons: PropTypes.bool,
  resultsComplete: PropTypes.shape(),
  openFastGed: PropTypes.func,
  titleFieldName: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['temporary', 'permanent']).isRequired,
};

ResultsComplete.defaultProps = {
  canAddToCart: false,
  canAddToNewsletter: false,
  canEditInGED: false,
  canFastEditInGED: false,
  canDeleteDocument: false,
  canSendToFriend: false,
  canComment: false,
  disableTagsRefine: false,
  withMoreLikeThis: true,
  showButtons: false,
  resultsComplete: {},
  openFastGed: _.noop,
};

export default ResultsComplete;
