import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
      },
      solidgauge: {
        colorByPoint: false,
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
    },
    chart: { type: 'solidgauge' },
  },
};

const SolidGauge = ({ highchartsOptions, containerId }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      const { chart } = chartRef.current;

      resizeObserver = new ResizeObserver(() => {
        if (!_.isEmpty(chart)) {
          chart.reflow();
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

SolidGauge.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.string,
};

SolidGauge.defaultProps = {
  containerId: null,
};

export default SolidGauge;
