export const types = {
  FETCH_WIDGETS: '@DASHBOARD/FETCH_WIDGETS',
  FETCH_WIDGET_SUCCESS: '@DASHBOARD/FETCH_WIDGET_SUCCESS',
  FETCH_WIDGET_ERROR: '@DASHBOARD/FETCH_WIDGET_ERROR',
  CLEANUP_DASHBOARD: '@DASHBOARD/CLEANUP_DASHBOARD',
  SET_DASHBOARD_LOADING: '@DASHBOARD/SET_DASHBOARD_LOADING',
};

/**
 * Action de lancement du fetch de tous les widgets
 * @param {String} query la stratégie de recherche
 * @param {Array} widgets paramètres des widgets, exemple [{ type: 'spline', aggregates: ['agg_PUB_DATE'] }, ...]
 * @param {Boolean} keepOtherWidgetsData précise si on veut garder les
 * données des "autres" widgets dans redux
 * @param {Boolean} keepAllWidgetsData précise si on veut garder les
 * données de tous les widgets (permet d'append aux données existantes
 * pour "networkgraph" notamment)
 * @param {String} exploreFrom le noeud depuis lequel on lance une exploration (uniquement pour "networkgraph")
 * @param {Integer} baseId l'ID de base (si non précisé, l'ID de la base active sera utilisé)
 * @returns {Object} l'action
 */
export const fetchWidgets = (
  query,
  widgets,
  keepOtherWidgetsData,
  keepAllWidgetsData,
  exploreFrom,
  baseId,
) => ({
  type: types.FETCH_WIDGETS,
  query,
  widgets,
  keepOtherWidgetsData,
  keepAllWidgetsData,
  exploreFrom,
  baseId,
});

export const fetchWidgetSuccess = (widgetId, widget) => ({
  type: types.FETCH_WIDGET_SUCCESS,
  widgetId,
  widget,
});

export const fetchWidgetError = (widgetId, response) => ({
  type: types.FETCH_WIDGET_ERROR,
  response,
  widgetId,
});

export const cleanupDashboard = () => ({
  type: types.CLEANUP_DASHBOARD,
});

export const setDashboardLoading = () => ({
  type: types.SET_DASHBOARD_LOADING,
});
